import Header from "../Components/Header";
import LinkCta from "../Components/LinkCta";
import Intro from "../Components/Intro";
import { Box, SimpleGrid, Spacer, useColorMode } from "@chakra-ui/react";
import rug from "../Images/rug.png";
import pathing from "../Images/pathgen.png";
import underConst from "../Images/led-scroller.png";
import podo from "../Images/podomoro.png";
import speakHelp from "../Images/speakhelp.png";

function Home() {
  const { colorMode } = useColorMode();
  return (
    <>
      <Header />
      <Box
        id="container"
        className="home"
        bg={colorMode === "dark" ? "gray.800" : "white"}
        color={colorMode === "dark" ? "white" : "black"}
        mx={{ base: 4, md: 8 }}
      >
        <Spacer h={10} />

        <Intro />

        <Spacer h={10} />

        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          spacing={10}
          justifyContent="center"
          alignItems="center"
          maxWidth="800px"
          mx="auto"
        >
          <LinkCta
            title="SpeakHelp.Org"
            content="Communication tools for people with speech difficulties"
            image={speakHelp}
            imageLink="https://speakhelp.org"
          />
          <LinkCta
            title="Podomoro Timer With Task List"
            content="A tool for practicing the Podomoro technique"
            image={podo}
            imageLink="https://jjarrard.github.io/podomoro/"
          />
          <LinkCta
            title="Free Under Contruction Page"
            content="An LED scroller looking holding page"
            image={underConst}
            imageLink="https://jjarrard.github.io/under-construction-LED-Scroller/"
          />
          <LinkCta
            title="Random User Generator"
            content="A Chrome extension for creating test JSON user objects"
            image={rug}
            imageLink="https://chrome.google.com/webstore/detail/random-user-generator/gifefcjlladpifaohabngaoadodhkjlg"
          />
          <LinkCta
            title="Basic Coordinate Pathing Tool"
            content="Generate paths that be used in crude CNC or robotics"
            image={pathing}
            imageLink="https://jjarrard.github.io/coordinate-pathing/"
          />
          <LinkCta
            title="The rest..."
            content="My GitHub"
            image={"https://avatars.githubusercontent.com/u/15104602?v=4"}
            imageLink="https://github.com/Jjarrard"
          />
        </SimpleGrid>
      </Box>
    </>
  );
}

export default Home;
