import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Spacer,
  Link,
  Image,
  Skeleton,
} from "@chakra-ui/react";

interface Props {
  title: string;
  content: string;
  image: string;
  imageLink: string;
}

const LinkCta: React.FC<Props> = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Flex
      direction="column"
      justify="space-between"
      height="100%"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      as={Link}
      href={props.imageLink}
      isExternal
      maxWidth="900px"
      cursor="pointer"
      userSelect="none"
      _hover={{ textDecoration: "none" }}
    >
      <Box>
        <Text
          fontWeight="bold"
          fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
          mb={2}
          bg="blue.700"
          color="white"
          p={2}
          borderRadius="md"
        >
          {props.title}
        </Text>
        <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={4}>
          {props.content}
        </Text>
      </Box>
      <Spacer />
      <Box>
        <Skeleton isLoaded={imageLoaded} borderRadius="md">
          <Image
            src={props.image}
            alt={`${props.title} image`}
            objectFit="contain"
            width="100%"
            maxHeight="500px"
            borderRadius="md"
            onLoad={() => setImageLoaded(true)}
          />
        </Skeleton>
      </Box>
    </Flex>
  );
};

export default LinkCta;
