import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
const BlogIntro: React.FC = () => {
  return (
    <Box p={4} maxW="800px" mx="auto">
      <Heading as="h1" size="2xl" mb={4} textAlign="center">
        Welcome to the Blog
      </Heading>
      <Text fontSize="lg" mb={4} textAlign="center">
        Discover articles, tutorials, and insights on various topics. Stay
        updated with the latest posts and enjoy reading!
      </Text>
    </Box>
  );
};

export default BlogIntro;
