// filepath: /C:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Box, ChakraProvider, Spacer } from "@chakra-ui/react";
import theme from "./Theme";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <App />
        <Spacer h={10} />

        <Box textAlign="center" fontSize="sm">
          © {new Date().getFullYear()} JarrardXyz
        </Box>

        <Spacer h={10} />
      </ChakraProvider>
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

reportWebVitals();
