import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Intro: React.FC = () => {
  return (
    <Box>
      <Text>Welcome to JarrardXyz,</Text>
      <Text>An incomplete portfolio of some of my side projects</Text>
      <Text>
        I'm a big believer in open source, so many of my apps are free.
      </Text>
      <small>Not that anyone would pay for them</small>
    </Box>
  );
};

export default Intro;
