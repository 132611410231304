// filepath: /c:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog"; // Import the Blog component
import Blog180125 from "./Blogs/18-01-25"; // Import the new blog post
import Blog260125 from "./Blogs/26-01-25";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/18-01-25" element={<Blog180125 />} />
          <Route path="/blog/26-01-25" element={<Blog260125 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
