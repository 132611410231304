import Header from "../Components/Header";
import LinkCta from "../Components/LinkCta";
import BlogIntro from "../Components/BlogIntro";
import {
  Box,
  Link,
  SimpleGrid,
  Spacer,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import rug from "../Images/rug.png";
import underConst from "../Images/led-scroller.png";
import podo from "../Images/podomoro.png";
import blogList from "../Blogs/blogList";
import { Link as RouterLink } from "react-router-dom";

function Blog() {
  const { colorMode } = useColorMode();
  return (
    <>
      <Header />
      <Box
        id="container"
        className="home"
        bg={colorMode === "dark" ? "gray.800" : "white"}
        color={colorMode === "dark" ? "white" : "black"}
        mx={{ base: 4, md: 8 }}
      >
        <Spacer h={10} />

        <BlogIntro />

        <Spacer h={10} />

        <VStack spacing={4} align="stretch">
          {blogList.map((blog, index) => (
            <Link
              as={RouterLink}
              to={blog.path}
              key={index}
              fontSize="lg"
              color="blue.500"
              _hover={{ textDecoration: "underline" }}
            >
              {blog.title}
            </Link>
          ))}
        </VStack>
      </Box>
    </>
  );
}

export default Blog;
