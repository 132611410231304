import React from "react";
import {
  Box,
  Text,
  Link,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { EmailIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  const headerPadding = "10px 20px"; // Adjust the padding as needed
  const boxShadow = "0 4px 10px rgba(0, 0, 0, 0.25)"; // Adjust shadow parameters as needed

  return (
    <>
      <Box
        as="header"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={headerPadding}
        boxShadow={boxShadow}
        bg="gray.800" // Ensure the background color is set
        width="100%" // Ensure the header spans the full width
      >
        <Heading
          as="h1"
          size="xl"
          textAlign="left"
          fontFamily="'Space Grotesk', sans-serif"
          color="white" // Ensure the text color is set
        >
          JarrardXyz
        </Heading>
        <Box display="flex" alignItems="center">
          <Link href="mailto:contact@jarrard.xyz">
            <Box display="flex" alignItems="center" padding={headerPadding}>
              <EmailIcon
                boxSize={{ base: 6, md: 8 }}
                color="white"
                marginRight={2}
                _hover={{ textDecoration: "underline" }}
              />
              <Text
                fontWeight="bold"
                display={{ base: "none", md: "inline" }}
                color="white"
              >
                contact@jarrard.xyz
              </Text>
            </Box>
          </Link>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
              ml={4}
              color="white"
              borderColor="white"
            />
            <MenuList>
              <MenuItem as={RouterLink} to="/">
                Home
              </MenuItem>
              <MenuItem as={RouterLink} to="/blog">
                Blog
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Spacer h={20} />
    </>
  );
};

export default Header;
